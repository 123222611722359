.main-title{
  position: relative;
  &:before {
    content: "";
    display: block;
    height: 143px;
    background: url(../images/main-title@2x.jpg) no-repeat center top;
    background-size: cover;
  }
  &:after {
    content: "";
    position:absolute;
    top:6px;
    left:calc(50% - 65px);
    display: block;
    width: 130px;
    height: 130px;
    background: url(../images/medaillon-1-medium@2x.png) no-repeat center ;
    background-size: cover;

  }
  .main-title__title{
    display:flex;
    justify-content: center;
    align-items:center;
    min-height:88px;
    background: rgb(174, 92, 57);
    background: linear-gradient(90deg, rgba(174, 92, 57, 1) 0%, rgba(229, 167, 129, 1) 30%, rgba(174, 92, 57, 1) 100%);
    color:$white;
    text-align: center;
    @media (max-width: $tab) {
      min-height: 70px;
    }
  }
  &--single{
    background: $marron-clair;
    .main-title__title {
      padding: 30px 0 20px 0;
      max-width: 890px;
      margin:auto;
      .title__date{
        margin-top:25px;
        font-size:16px;
      }
    }
  }
  &--green{
    &:before {
      background: url(../images/main-title-2@2x.jpg) no-repeat center top;
      background-size: cover;
    }
    &:after {
      background: url(../images/medaillon-2-medium@2x.png) no-repeat center;
      background-size: cover;
  
    }
    .main-title__title {
      background: $green;
    }
  }
  &--marron-clair {
    &:before, &:after {
      display:none;
    }
    

    .main-title__title {
      background: $marron-clair;
    }
  }
}