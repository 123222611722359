.heritage{
    position:relative;
    display: flex;
    @media (max-width: $mob) {
        flex-direction: column;
    }
    .heritage__text {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width:50%;
        padding:50px 30px;
        box-sizing: border-box;
        @media (max-width: $tab) {
            padding: 50px 15px;
        }
        @media (max-width: $mob) {
            width: 100%;
            padding: 40px 15px;
        }
        .text{
            max-width:600px;
            padding:10px;
            box-sizing: border-box;
            border:2px solid $creme;
            @media (max-width: $mob) {
                max-width: none;
            }
            .text__title{
                padding:50px 55px 22px 55px;
                border: 2px solid $creme;
                @media (max-width: $desksm) {
                    padding: 40px 35px 22px 35px;
                }
                @media (max-width: $tab) {
                    padding: 22px 15px;
                }
            }
            .text__content{
                padding: 30px 55px 20px 55px;
                border: 2px solid $creme;
                border-top:0;
                @media (max-width: $desksm) {
                    padding: 25px 35px 22px 35px;
                }
                @media (max-width: $tab) {
                    padding: 22px 15px;
                }
                p {
                    text-align: justify;
                }
            }
            
        }
    }
    .heritage__img{
        position:absolute;
        top:0;
        right:0;
        width: 50%;
        height: 100% !important;
        object-fit: cover;
        object-position: top left;
        @media (max-width: $mob) {
            position: relative;
            top:auto;
            left: auto;
            right: auto;
            width: 100%;
            height: auto !important;

        }
    }

    &--reverse {
        flex-direction: row-reverse;
        @media (max-width: $mob) {
            flex-direction: column;
        }
        .heritage__text {
            justify-content: flex-start;
            
        }
        .heritage__img {
            left:0;
        }
        
    }
}
