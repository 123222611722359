
.card{
    position:relative;
    height: auto;
    box-sizing: border-box;
    margin-bottom:50px;
    @media (max-width: $mob) {
        margin-bottom: 30px;
    }
    background:$white;
    .card__img{
        display:block;
        width:100%;
        height:185px !important;
        object-fit: cover;
        @media (max-width: $desksm) {
            height: 233px!important;
        }
        @media (max-width: $tab) {
            height: 309px!important;
        }
        @media (max-width: $mob) {
            height: auto !important;
            aspect-ratio: 3 / 2;
        }
    }
    .card__category{
        position:absolute;
        top:0;
        left:0;
        display: flex;
        justify-content: center;
        width:100%;
        & > div{
            padding: 4px 18px;
            background: $white;
            color: $white;
            font-size: 16px;
        }
    }
    .card__content{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: calc(100% - 230px);
        padding: 20px 17px 25px;
        font-size: 16px;
        @media (max-width: $desksm) {
            height: calc(100% - 273px);
        }
        @media (max-width: $tab) {
            height: calc(100% - 349px);
            padding: 20px 15px 20px 15px;
        }
        @media (max-width: $mob) {
            height:auto;
        }
        .content__title{
            font-size:16px;
            line-height:22px;
        }
        .content__link{
            margin-top:15px;
            font-size: 16px;
            a{
                color: $rose ;
                text-decoration: none;
                font-weight: bold;
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}

.row {
    .card {
        height: calc(100% - 35px);
    }
}