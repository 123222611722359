.title{
    font-family: $font-2;
    font-weight: 700;
    font-size: 35px;
    text-transform: uppercase;
    @media (max-width: $mob) {
        font-size: 28px;
    }
    &--l {
        font-size: 30px;
        @media (max-width: $mob) {
            font-size: 25px;
        }
    }
    &--m{
        font-size:25px;
        @media (max-width: $mob) {
            font-size: 20px;
        }
    }
    &--s{
        font-size: 25px;
        text-transform: initial;
        @media (max-width: $mob) {
            font-size: 22px;
        }
    }
    &--xs {
        font-size: 22px;
        @media (max-width: $mob) {
            font-size: 20px;
        }
    }
}