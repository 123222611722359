.link{
    color:$orange;
    font-weight:600;
    text-decoration:underline;
    &:hover{
        text-decoration:none;
    }
}
.link-black{
    color: $black;
    text-decoration: underline;
    &:hover {
        text-decoration: none;
    }
}