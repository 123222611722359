.clean-fragrance{
  padding:120px 0;
  background:url(../images/bg-clean-fragrance@2x.jpg) no-repeat center;
  background-size: cover;
  @media (max-width: $mob) {
    padding: 50px 0;
  }
  .clean-fragrance__content{
    display:flex;
    border:2px solid $creme;
    padding:7px;
    background:$white;
    @media (max-width: $mob) {
      display:block;
    }
    .content__text{
      width:calc(100% - 390px);
      box-sizing: border-box;
      border: 2px solid $creme;
      @media (max-width: $tab) {
        width: calc(100% - 280px);
      }
      @media (max-width: $mob) {
        width:100%;
      }
      .text__title{
        padding:40px 65px 35px 65px;
        border-bottom:2px solid $creme;
        @media (max-width: $tab) {
          padding: 30px 35px 25px 35px;
        }
        @media (max-width: $mob) {
          padding: 30px 15px 25px 15px;
        }
      }
      .text__text{
        padding: 40px 65px 35px 65px;
        @media (max-width: $tab) {
          padding: 30px 35px 25px 35px;
        }
        @media (max-width: $mob) {
          padding: 30px 15px 25px 15px;
        }
        p{
          text-align: justify;
        }
      }
    }
    .content__img{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 390px;
      padding:20px 10px;
      box-sizing: border-box;
      border: 2px solid $creme;
      border-left:0;
      @media (max-width: $tab) {
        width: 280px;
      }
      @media (max-width: $mob) {
        width: 100%;
        border: 2px solid $creme;
        border-top: 0;
      }
    }
  }
}