.home-intro{
  position:relative;
  margin-top:-126px;
  @media (max-width: $desksm) {
    margin-top: 0;
  }
  .home-intro__video{
    display:block;
    width:100%;
    height:auto;
  }
  .home-intro__content{
    position:absolute;
    top:0;
    left:0;
    display:flex;
    align-items: center;
    justify-self: center;
    width:100%;
    height:100%;
    background:rgba($black, 0.3);
    .content__title{
      max-width:760px;
      padding:40px 0;
      margin:auto;
      font-size:40px;
      text-align:center;
      @media (max-width: $tab) {
        font-size: 35px;
      }
      @media (max-width: $mob) {
        font-size: 30px;
      }
    }
  }
  
  .header-home{
    position:absolute;
    top: 40px;
    left: 0;
    width: 100%;
    text-align: center;
    @media (max-width: $desksm) {
      display:none;
    }
    .header-home__content {  
      .content__logo {
        .logo {
          display: inline;
          @media (max-width: $tab) {
            width: 120px;
          }
        }
      }
  
      .content__menu {
        position:relative;
        padding-top: 30px;
        transition: all 0.2s;
  
        &.open {
          right: 0;
        }
  
        @media (max-width: $desksm) {
          position: fixed;
          top: 126px;
          right: -100%;
          max-width: calc(100vw - 30px);
          height: calc(100% - 126px);
          margin-left: 0;
          padding: 15px 30px;
          box-sizing: border-box;
          background: $white;
        }
  
        @media (max-width: $tab) {
          top: 80px;
          height: calc(100% - 80px);
          padding: 15px 20px;
        }
  
        @media (max-width: $mob) {
          top: 65px;
          height: calc(100% - 65px);
          padding: 15px 15px;
        }
  
        .menu {
          display: flex;
          justify-content: center;
          gap: 0 40px;
          margin: 0;
          padding: 0;
          list-style: none;
  
          @media (max-width: $desksm) {
            display: block;
          }
  
          .menu-item {
            padding-bottom: 20px;
            font-size: 12px;
            text-transform: uppercase;
  
            @media (max-width: $desksm) {
              font-size: 16px;
              padding-bottom: 0;
            }
  
            @media (max-width: $mob) {
              font-size: 15px;
            }
  
            a {
              position: relative;
              color: $white;
              text-decoration: none;
  
              &:before {
                content: "";
                position: absolute;
                top: 100%;
                right: 50%;
                width: 0%;
                padding-top: 4px;
                border-bottom: 1px solid $white;
                transition: all 0.2s;
  
                @media (max-width: $desksm) {
                  display: none;
                }
              }
  
              &:after {
                content: "";
                position: absolute;
                top: 100%;
                left: 50%;
                width: 0%;
                padding-top: 4px;
                border-bottom: 1px solid $white;
                transition: all 0.2s;
  
                @media (max-width: $desksm) {
                  display: none;
                }
              }
  
              &:hover {
                &:before {
                  width: 50%;
                }
  
                &:after {
                  width: 50%;
                }
              }
            }
          }
  
          .menu-item-has-children {
            &:hover {
              .sub-menu {
                opacity: 1;
                visibility: visible;
              }
            }
  
            .sub-menu {
              z-index: $z-menu;
              position: absolute;
              top: 65px;
              left: 0;
              width: 100%;
              height: 66px;
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 0 50px;
              list-style: none;
              background: none;
              border:1px solid $white;
              opacity: 0;
              visibility: hidden;
              transition: all 0.2s;
  
              @media (max-width: $desksm) {
                position: relative;
                top: auto;
                left: auto;
                opacity: 1;
                visibility: visible;
                display: block;
                height: auto;
                padding: 0;
                background: transparent;
                border: 0;
                border-radius: 0;
              }
  
              li {
                margin: 0;
                padding: 0;
  
                @media (max-width: $desksm) {
                  font-weight: 400;
                  border: 0;
                  margin-top: 0;
                  margin-bottom: 10px;
                  padding: 0 0 0 20px;
                }
  
                a {
                  display: block;
                  color: $white;
  
                  @media (max-width: $desksm) {
                    color: $white;
                  }
  
                  &:before,
                  &:after {
                    border-color: $white;
                  }
                }
              }
            }
          }
        }
      }
  
      .content__lang {
        position: absolute;
        top: -50px;
        right: 0;
        display: flex;
        gap: 0 10px;
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 12px;
        text-transform: uppercase;
  
        @media (max-width: $desksm) {
          position: absolute;
          top: auto;
          right: auto;
          margin-top: 35px;
          font-size: 16px;
        }
  
        @media (max-width: $mob) {
          font-size: 15px;
        }
  
        .lang-item {
          margin: 0;
          padding-bottom: 2px;
  
          &:hover {
            border-bottom: 1px solid $white;
          }
  
          &.current-lang {
            border-bottom: 1px solid $white;
          }
  
          a {
            color: $white;
            text-decoration: none;
          }
        }
  
  
      }
  
      .content__button-mobile {
        display: none;
  
        @media (max-width: $desksm) {
          display: block;
          position: absolute;
          top: 43px;
          right: 0;
          font-size: 26px;
          cursor: pointer;
          transition: all 0.2s;
        }
  
        @media (max-width: $tab) {
          top: 20px;
        }
  
        @media (max-width: $mob) {
          top: 13px;
        }
  
        .button-mobile__open {}
  
        .button-mobile__close {
          display: none;
        }
  
        &.open {
          .button-mobile__open {
            display: none;
          }
  
          .button-mobile__close {
            display: block;
          }
        }
      }
    }
  }
}