.commitments{

  &.active {
    .commitments__content {
      .list__item {
        &:before {
          transform: scale(1);
        }
        &:after {
          transform: scale(1);
        }
        .item__picto {
          transform: scale(1);
          @media (max-width: $desksm) {
            transform: none;
          }
        }
        .item__text {
          transform: scale(1);
          @media (max-width: $desksm) {
            transform: none;
          }
        }
        &--responsible {
          &:after {
            transform: scale(1) rotate(-35deg);
          }
        }
        &--sustainable {
          &:after {
            transform: scale(1) rotate(35deg);
          }
        }
      }
    }
  }
  
  .commitments__content{
    position:relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height:740px;
    @media (max-width: $desksm) {
      display:block;
      height:auto;
    }
    .content__title{
      position:relative;
      display: flex;
      align-items: center;
      justify-content: center;
      width:312px;
      height:312px;
      background: $creme;
      border-radius:312px;
      z-index:1;
      @media (max-width: $desksm) {
        width: auto;
        height: 200px;
        background: none;
        border-radius: 0;
      }
      &:before{
        content:"";
        position: absolute;
        top:80px;
        width:66px;
        border-top:2px solid $marron;
        @media (max-width: $desksm) {
          top:40px;
        }
      }
      &:after {
        content: "";
        position: absolute;
        bottom: 80px;
        width: 66px;
        border-top: 2px solid $marron;
        @media (max-width: $desksm) {
          bottom: 40px;
        }
      }
      .title__title{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 290px;
        height: 290px;
        border:2px solid $marron;
        border-radius: 312px;
        text-align: center;
        font-size:23px;
        @media (max-width: $desksm) {
          width: auto;
          height: 200px;
          background: none;
          border-radius: 0;
          border:0;
        }
      }
    }
    .list__item{
      transform-origin: center;
      @media (max-width: $desksm) {
        display:flex;
        align-items: center;
        margin-bottom: 30px;
      }
      @media (max-width: $mob) {
        align-items: flex-start;
      }
      &:before{
        transition: all 0.2s;
        transform: scale(0);
        @media (max-width: $desksm) {
          display:none
        }
      }
      &:after{
        transition: all 0.2s;
        transform: scale(0);
        @media (max-width: $desksm) {
         display:none;
        }
      }
      .item__picto{
        position: absolute;
        top:0;
        transition: all 0.2s;
        transform: scale(0);
        @media (max-width: $desksm) {
          position:relative;
          top: auto !important;
          left:auto!important;
          right:auto!important;
          transform: none;
        }
        @media (max-width: $mob) {
          width:80px;
        }
      }
      .item__text{
        position: absolute;
        top:0;
        transition: all 0.2s;
        transform: scale(0);
        @media (max-width: $desksm) {
          position: relative;
          top: auto !important;
          left: auto !important;
          right: auto !important;
          transform: none;
          width: calc(100% - 116px) !important;
          padding-left:20px;
          box-sizing: border-box;
          text-align: left !important;
        }
        @media (max-width: $mob) {
          width: calc(100% - 80px) !important;
          padding-left: 10px;
        }
        .text__title{
          font-size: 15px;
          font-style: normal;
          font-weight: 700;
          text-transform: uppercase;
        }
        p{
          font-size: 14px;
          line-height:19px;
          margin-bottom:0;
          @media (max-width: $desksm) {
            padding:0 !important;
          }
        }        
      }
      &--responsible{
        
        &:before{
          content: "";
          position:absolute;
          top: 141px;
          right: 247px;
          width:240px;
          height:82px;
          border:2px solid $marron;
          border-left:0;
          border-top:0;
          transition-delay: 0;

        }
        &:after {
          content: "";
          position: absolute;
          top: 234px;
          right: 485px;
          width: 40px;
          border-top: 2px solid $marron;
          transition-delay: 0s;
        }
        .item__picto {
          top: 100px;
          right: 100px;
          transition-delay: 0s;
        }
        .item__text {
          top: 100px;
          right: 250px;
          width: 283px ;
          text-align: right;
          transition-delay: 0s;
          p{
            padding-right:10px;
          }
        }
      }
      &--territorial{
        &:before {
          content: "";
          position: absolute;
          top: 327px;
          right: 153px;
          width: 318px;
          height: 110px;
          border: 2px solid $marron;
          border-left: 0;
          border-top: 0;
          transition-delay: 0.2s;
        }
        .item__picto {
          top: 315px;
          right: 11px;
          transition-delay: 0.2s;
        }
        .item__text {
          top: 290px;
          right: 160px;
          width: 250px;
          text-align: right;
          transition-delay: 0.2s;
          p {
            padding-right: 10px;
          }
        }
      }
      &--satisfaction{
        &:before {
          content: "";
          position: absolute;
          top: 525px;
          left: 263px;
          width: 341px;
          height: 95px;
          border: 2px solid $marron;
          border-top: 0;
          transition-delay: 0.4s;
        }
        .item__picto {
          top: 495px;
          left: 121px;
          transition-delay: 0.4s;
        }
        .item__text {
          top: 490px;
          left: 268px;
          width: 250px;
          text-align: left;
          transition-delay: 0.4s;
          p {
            padding-left: 10px;
          }
        }
      }
      &--social{
        &:before {
          content: "";
          position: absolute;
          top: 340px;
          left: 103px;
          width: 350px;
          height: 85px;
          border: 2px solid $marron;
          border-top: 0;
          border-right:0;
          transition-delay: 0.6s;
        }
      
        .item__picto {
          top: 295px;
          left: -29px;
          transition-delay: 0.6s;
        }
      
        .item__text {
          top: 299px;
          left: 109px;
          width: 290px;
          text-align: left;
          transition-delay: 0.6s;
      
          p {
            padding-left: 10px;
          }
        }
      }
      &--sustainable{
        &:before {
          content: "";
          position: absolute;
          top: 139px;
          left: 213px;
          width: 240px;
          height: 84px;
          border: 2px solid $marron;
          border-top: 0;
          border-right: 0;
          transition-delay: 0.8s;
        }
        &:after {
          content: "";
          position: absolute;
          top: 238px;
          left: 450px;
          width: 54px;
          border-top: 2px solid $marron;
          transition-delay: 0.8s;
        }
        .item__picto {
          top: 115px;
          left: 71px;
          transition-delay: 0.8s;
        }
        .item__text {
          top: 98px;
          left: 219px;
          width: 285px;
          text-align: left;
          transition-delay: 0.8s;
          p {
            padding-left: 10px;
          }
        }
      }
    }
  }
}