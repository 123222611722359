.footer{
  position: relative;
  padding:65px 0 15px 0;
  background:$creme;
  .footer__content{
    display: flex;
    justify-content: space-between;
    max-width:1000px;
    margin: auto;
    font-size:14px;
    @media (max-width: $mob) {
      flex-direction: column;
      align-items: center;
      text-align:center;
      gap: 50px 0;
    }
    .content__menu{
      .menu{
        margin: 0;
        padding-left:0;
        list-style: none;
        a{
          color:$black;
          text-decoration: none;
          transition: all 0.2s;
          &:hover {
            text-decoration: underline;
            color: $rose;
          }
        }
      }
    }
    .content__social{
      align-self: center;
      .social__title{
        text-transform: uppercase;
        text-align:center;
      }
      .social__links{
        display: flex;
        justify-content: center;
        gap: 0 10px;
        margin-top:10px;
        .links__item{
          display: flex;
          justify-content: center;
          align-items: center;
          width:32px;
          height:32px;
          background:$black;
          border-radius:32px;
          color:$white;
          font-size:18px;
          text-decoration: none;
          transition: all 0.2s;
          &:hover{
            background: $rose;
          }
        }
      }
    }
  }
  .footer__bottom{
    margin-top:70px;
    font-size:11px;
    text-align: center;
    a{
      color:$black;
      text-decoration: underline;
      transition: all 0.2s;
      &:hover{
        text-decoration: none;
        color:$rose;
      }
    }
  }
}