.cards-list{
    display:flex;
    flex-wrap: wrap;
    margin-left:-15px;
    margin-right:-15px;
    .card{
        width:calc(25% - 30px);
        margin:0 15px 30px 15px;
        @media (max-width: $desksm) {
            width: calc((100% / 3) - 30px);
        }
        @media (max-width: $tab) {
            width: calc(50% - 30px);
        }
        @media (max-width: $mob) {
          width:100%;
        }
    }
}