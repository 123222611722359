.chiffres-cles-secteurs{
  padding:100px 0 70px 0;
  background:$creme;
  .chiffres-cles-secteurs__content{
    display:flex;
    justify-content: center;
    &.active{
      .content__goutte {
        .goutte__anim {
          height:0;
        }
      }
      .content__text {
        .text__item {
          transform: translateX(0);
          opacity: 1;
          visibility: visible;
        }
      }
    }
    .content__text{
      margin-left:75px;
      @media (max-width: $tab) {
        margin-left:45px;
      }
      .text__item{
        position:relative;
        display:flex;
        align-items:center;
        transform: translateX(-40px);
        opacity:0;
        visibility: hidden;
        transition: all 0.2s;
        @media (max-width: $mob) {
          transform: translateX(0);
          opacity: 1;
          visibility: visible;
        }
        &::before{
          content:"";
          position:absolute;
          right:calc(100% + 25px);
          display:block;
          border-bottom: 1px solid $rose;
          @media (max-width: $mob) {
            display: none;
          }
        }
        &:after{
          content: "";
          position: absolute;
          right: calc(100% + 25px);
          display: block;
          width:5px;
          height:5px;
          background:$rose;
          border-radius:5px;
          @media (max-width: $mob) {
            display: none;
          }
        }
        &--technical {
          margin-top:-30px; 
          transition-delay: 2s;
          &::before {
            width: 285px; 
            @media (max-width: $tab) {
              width: 260px;
            }
          }
          &::after {
            right: calc(100% + 310px);
            @media (max-width: $tab) {
              right: calc(100% + 285px);
            }
          }
        }
      
        &--home {
          margin-top: 15px;
          transition-delay: 1.85s;
          &::before {
            width: 155px;
            @media (max-width: $tab) {
              width: 135px;
            }
          }
          &::after {
            right: calc(100% + 180px);
            @media (max-width: $tab) {
              right: calc(100% + 155px);
            }
          }
        }
      
        &--personal {
          margin-top: 15px;
          transition-delay: 1.7s;
          &::before {
            width: 75px;
            @media (max-width: $tab) {
              width: 50px;
            }
          }
          &::after {
            right: calc(100% + 100px);
            @media (max-width: $tab) {
              right: calc(100% + 75px);
            }
          }
        }
      
        &--household {
          margin-top: 35px;
          transition-delay: 1.55s;
          &::before {
            width: 35px;
            @media (max-width: $tab) {
              width: 10px;
            }
          }
          &::after {
            right: calc(100% + 60px);
            @media (max-width: $tab) {
              right: calc(100% + 35px);
            }
          }
        }
      
        &--fine {
          margin-top: 38px;
          transition-delay: 1.4s;
          &::before {
            width: 60px;
            @media (max-width: $tab) {
              width: 35px;
            }
          }
          &::after {
            right: calc(100% + 85px);
            @media (max-width: $tab) {
              right: calc(100% + 60px);
            }
          }
        }
        .item__number{
          width:74px;
          font-family: $font-2;
          font-size: 45px;
          font-weight: bold;
          color:$rose;
          text-align:center;
          span{
            font-size: 25px;
          }
        }
        .item__icon{
          width:100px;
          margin-left:65px;
          text-align:center;
          @media (max-width: $tab) {
            margin-left: 30px;
          }
          img{
            display: inline;
          }
        }
        .item__name{
          margin-left:15px;
          font-family: $font-2;
          font-size:15px;
          font-weight: bold;
          text-transform : uppercase;
          line-height:1.2
        }
      }
    }
    .content__goutte{
      position:relative;
      width: 304px;
      height: 465px;
      background: $white;
      @media (max-width: $mob) {
        display:none;
      }
      .goutte__mask {
        position: absolute;
        top:0;
        left:0;
        width: 304px;
        height: 465px;
        background: url(../images/goutte-mask@2x.png);
        background-size:cover;
      }
      .goutte__anim{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height:100%;
        background:$white;
        transition: all 2s cubic-bezier(0, 0, 0.3, 1.01);
      }
      .goutte__secteur{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        &--technical{
          top:0;
          height:10%;
          background: $white
        }
        &--home {
          top: 10%;
          height: 15%;
          background: darken($creme, 2%);
        }
        &--personnal {
          top: 25%;
          height: 20%;
          background: rgb(209, 158, 133);
          background: linear-gradient(90deg, rgba(209, 158, 133, 1) 0%, rgba(238, 205, 180, 1) 60%, rgba(210, 161, 135, 1) 100%);
        }
        &--household {
          top: 45%;
          height: 25%;
          background: rgb(179, 93, 51);
          background: linear-gradient(90deg, rgba(179, 93, 51, 1) 0%, rgba(217, 153, 112, 1) 100%);
        }
        &--fine {
          top: 70%;
          height: 30%;
          background: rgb(174, 85, 44);
          background: linear-gradient(90deg, rgba(174, 85, 44, 1) 0%, rgba(228, 171, 132, 1) 60%, rgba(174, 85, 44, 1) 100%);
        }
      }
    }
  }
}