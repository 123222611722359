.noscroll {
  overflow-y: hidden;
  margin-right:17px;
  @media (max-width: $tab) {
    margin-right:0;
  }
}

.display-inline{
  display: inline;
}
.display-none{
  display:none;
}
.center {
  text-align: center;
}
.justify {
  text-align: justify;
}

.italic {
  font-style: italic;
}

.justify-center{
  justify-content: center;
}

.hide-mobile{
  @media (max-width: $mob) {
    display:none !important;
  }
}

.color-white{
  color: $white;
}
.color-ocre{
  color: $ocre;
}
.color-green{
  color: $green;
}
.color-rose{
  color: $rose;
}

.bg-creme {
  background: $creme;
}
.bg-white{
  background: $white;
}

.w-100{
  width:100%;
}
.h-100{
  height: 100%;
}

.inline{
  display: inline;
}

.flex{
  display:flex;
} 

.align-items-center{
  align-items: center;
}

.mt-xl{
  margin-top:85px;
  @media (max-width: $mob) {
    margin-top: 50px;
  }
}
.pt-xl {
  padding-top: 80px;

  @media (max-width: $mob) {
    padding-top: 50px;
  }
}
.pb-xl {
  padding-bottom: 80px;

  @media (max-width: $mob) {
    padding-bottom: 50px;
  }
}

.mt-l {
  margin-top: 50px;
}
.pt-l {
  padding-top: 50px;
}

.mt-m {
  margin-top:30px;
}

.mt-s {
  margin-top:20px;
}
.mt-xs {
  margin-top: 20px;
}
.mt-xxs{
  margin-top: 10px;
}

.fs-14{
  font-size:14px;
}
.fs-15 {
  font-size: 15px;
}
.fs-17{
  font-size:17px;
}
.fs-18 {
  font-size:18px;
}
.fs-19 {
  font-size:19px;
}
.fs-22 {
  font-size: 23px;
}
.fs-23{
  font-size: 23px;
}
