.page-404{
    display:flex;
    align-items: center;
    min-height:calc(100vh - 500px);
    background: url(../images/prise.svg) no-repeat center $creme;
    background-size: contain;
    text-align:center;
    @media (max-width: $desksm) {
        background-size:auto;
    }
    @media (max-width: $mob) {
        background: $creme;
        min-height: auto;
    }
    .wrap{
        max-width:470px;
        padding-top: 150px;
        padding-bottom:50px;
        @media (max-width: $mob) {
            padding-top: 100px;
            padding-bottom:100px;
        }
    }
    .page-404__title{
        font-family:$font-2;
        font-size:30px;
        @media (max-width: $mob) {
            min-height: auto;
        }
        .title__404{
            font-size:100px;
            line-height:90px;
            font-weight:bold;
        }
    }
    .page-404__text{
        margin-top:30px;
    }
    .page-404__button{
        margin-top: 30px;
    }
}