.carriere-intro{
  position:relative;
  min-height:559px;
  background: $creme;
  overflow: hidden;
  @media (max-width: $mob) {
    min-height:auto;
  }
  .carriere-intro__s{
    position:absolute;
    top:0;
    left:0;
    width: 365px;
    @media (max-width: $desksm) {
      left: -200px;
    }
    @media (max-width: $tab) {
      display:none;
    }
  }
  .carriere-intro__img{
    position: absolute;
    top: 0;
    right: 0;
    
    @media (max-width: $mob) {
      position:relative;
      top:auto;
      left:auto;
      width:100%;
    }
  }
  .carriere-intro__content{
    position: absolute;
    top: 250px;
    left: calc(50% - 250px);
    width:420px;
    @media (max-width: $desksm) {
      top: 250px;
      left: calc(50% - 350px);
    }
    @media (max-width: $tab) {
      position:relative;
      top: auto;
      left: auto;
      display:flex;
      flex-direction: column;
      justify-content: center;
      height:559px;
      max-width:calc(100% - 420px);
    }
    @media (max-width: $mob) {
      width:100%;
      height:auto;
      max-width: none;
      padding:50px 0 30px 0 ;
      text-align:center;
    }
    .content__title{ 
    }
  }
}