.button{
    position:relative;
    display:inline-flex;
    align-items: center;
    justify-content: center;
    min-height:40px;
    box-sizing: border-box;
    padding:2px 20px;
    background: $rose;
    border:1px solid $rose;
    font-size:16px;
    color:$white;
    text-decoration:none;
    text-align:center;
    transition: all 0.2s;
    &:hover{
        background:$rose-dark;
        border-color: $rose-dark;
        color: $white;
    }

    &--w100{
        width:100%;
    }

    &--outline-black {
        background: transparent;
        border-color: $black;
        color: $black;
    }
    
}