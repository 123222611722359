.engagements{
  .engagements__title{
    padding:45px 0;
    background:$creme;
  }
  .engagements__content{
    max-width:1200px;
    margin:50px auto 90px auto;
    padding: 6px;
    border: 2px solid $creme;
    .content__container{
      border: 1px solid $creme;
      .container__block {
        display:flex;
        @media (max-width: $mob) {
          display: block;
        }
        .block__text {
          width: 50%;
          box-sizing: border-box;
          border: 1px solid $creme;
          text-align: justify;
          @media (max-width: $mob) {
            width: 100%;
          }
          .text__title{
            padding:50px 65px 28px 65px;
            border-bottom: 2px solid $creme;
            @media (max-width: $desksm) {
              padding: 50px 40px 28px 40px;
            }
            @media (max-width: $tab) {
              padding: 35px 30px 25px 30px;
            }
            @media (max-width: $mob) {
              padding: 35px 15px 25px 15px;
            }
          }
          .text__text{
            padding: 30px 65px 28px 65px;
            @media (max-width: $desksm) {
              padding: 30px 40px 28px 40px;
            }
            @media (max-width: $tab) {
              padding: 25px 30px 20px 30px;
            }
            @media (max-width: $mob) {
              padding: 25px 15px 10px 15px;
            }
          }
        }
        .block__img{
          display:block;
          width: 50%;
          object-fit: cover;
          box-sizing: border-box;
          border: 1px solid $creme;
          @media (max-width: $mob) {
            width: 100%;
          }
        }
        &--reverse {
          flex-direction: row-reverse;
        }
      }
    }
  }
}