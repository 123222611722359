.goutte-anim-bio{
  position:relative;
  height: 470px;
  overflow: hidden;
  @media (max-width: $tab) {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
  }
  .goutte-anim-bio__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 55%;
    object-fit:cover;
    height: 470px !important;
    @media (max-width: $tab) {
      height: auto !important;
    }
  }
  .goutte-anim-bio__goutte {
    position:absolute;
    top:0;
    right: 0;
    width: 100%;
    height: 470px;
    background: $green;
    transition: all ease-out 0.4s;
    @media (max-width: $tab) {
      display:none;
    }
    & > div{
      position:relative;
      width:100%;
      .goutte__img{
        position:absolute;
        top:0;
        right:100%;
        height: 470px;
      }
    }
  }
  .goutte-anim-bio__text {
    position:absolute;
    top:0;
    right:0;
    display: flex;
    align-items: center;
    width:100%;
    height:100%;
    @media (max-width: $tab) {
      position: relative;
      top: auto;
      right: auto;
      height: auto;
      background: $green;
      padding: 50px 0;
    }
    .wrap{
      display: flex;
      justify-content: flex-end;
    }
    .text__content{
      width:450px;
      color:$white;
      text-align:justify;
      opacity:0;
      visibility: hidden;
      transform: translateY(30px);
      transition: all 0.3s;
      @media (max-width: $tab) {
        opacity:1;
        visibility: visible;
        transform: translateY(0);
        width: 100%;
        text-align: center;
      }
      p{
        &:last-child{
          margin-bottom:0;
        }
      }
    }
  }
  &.active {
    .goutte-anim-bio__goutte {
     right: 0;
     width: 45%;
      @media (max-width: $desksm) {
        width: 48%;
      }
    
      @media (max-width: $tab) {
        width: 100%;
      }
    }
    .goutte-anim-bio__text {
      .text__content {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        transition-delay: 0.4s;
      }
    }
  }
}