.goutte-anim{
  position:relative;
  height: 465px;
  overflow: hidden;
  @media (max-width: $tab) {
    display: flex;
    flex-direction: column-reverse;
    height: auto;
  }
  .goutte-anim__img {
    position:absolute;
    top:0;
    right:0;
    width:57%;
    height: 465px !important;
    object-fit:cover;
    @media (max-width: $tab) {
      height: auto !important;
    }
  }
  .goutte-anim__goutte {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height: 465px;
    background: $ocre-light;
    background: linear-gradient(90deg, rgba($ocre, 1) 0%, rgba(228, 165, 127, 1) 63%, rgba($ocre-light, 1) 100%);
    transition: all ease-out 0.4s;
    @media (max-width: $tab) {
      display: none;
    }
    & > div{
      position:relative;
      width:100%;
      .goutte__img{
        position:absolute;
        top:0;
        left:100%;
        height: 465px;
      }
    }
  }
  .goutte-anim__text {
    position:absolute;
    top:0;
    left:0;
    display: flex;
    align-items: center;
    width:100%;
    height:100%;
    @media (max-width: $tab) {
      position: relative;
      top: auto;
      left: auto;
      height: auto;
      background: $ocre-light;
        background: linear-gradient(90deg, rgba($ocre, 1) 0%, rgba(228, 165, 127, 1) 63%, rgba($ocre-light, 1) 100%);
      padding: 50px 0;
    }
    .text__content{
      width:426px;
      color:$white;
      text-align:justify;
      opacity:0;
      visibility: hidden;
      transform: translateY(30px);
      transition: all 0.3s;
      @media (max-width: $tab) {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        width: 100%;
        text-align: center;
      }
      p{
        &:last-child{
          margin-bottom:0;
        }
      }
    }
  }
  &.active {
    .goutte-anim__goutte {
      left: 0;
      width: 43%;
      @media (max-width: $desksm) {
        width: 48%;
      }
    
      @media (max-width: $tab) {
        width: 100%;
      }
    }
    .goutte-anim__text {
      .text__content {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        transition-delay: 0.4s;
      }
    }
  }

}