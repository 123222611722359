.article-img{
  padding-top:50px;
  max-height:650px;
  object-fit: cover;
}

.article-content{
  padding:10px;
  background:$white;
  & > div{
    border: 2px solid $creme;
    padding:55px 70px;
    @media (max-width: $tab) {
      padding: 35px 50px;
    }
    @media (max-width: $mob) {
      padding: 20px 20px;
    }
  }
  h2, h3, h4{
    font-family:$font-2;
    font-weight: 700;
  }
  h2{
    text-transform: uppercase;
    margin-bottom:20px;
  }
}