.projet-contact{
  position:relative;
  padding:100px 0;
  background:url(../images/bg-block-contact@2x.jpg) no-repeat center;
  background-size: cover;
  .projet-contact__video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: hue-rotate(317deg);
  }
  .projet-contact__content{
    position:relative;
    max-width:720px;
    margin:auto;
    padding: 40px 120px 60px 120px;
    box-sizing: border-box;
    background:$white;
    text-align:center;
    @media (max-width: $mob) {
      padding: 40px 30px 50px 30px;
    }
  }
}