.frise-chrono{
  display:flex;
  overflow: hidden;
  @media (max-width: $tab) {
    flex-direction: column;
    margin-top:30px;
  }
  .frise-chrono__item{
    position:relative;
    width: calc(100% / 13); 
    transition: all 0.4s;
    @media (max-width: $tab) {
      width:100%;
    }
    .item__img{
      width:100%;
      height:617px !important;
      object-fit: cover;
      @media (max-width: $tab) {
        height: 80px !important;
        transition: all 0.3s;
      }
    }
    .item__shade{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: #989898;
      mix-blend-mode: multiply;
    }
    .item__text{
      position: absolute;
      top:27vh;
      left: 0;
      width: 100%;
      padding: 0 2vw;
      box-sizing: border-box;
      color:$white;
      transition: all 0.3s;
      @media (max-width: $tab) {
        top:20px;
        padding: 0;
        padding-left:calc( 50% - 30px);
      }
      .text__date{
        width: calc(100vw / 13);
        font-family:$font-2;
        font-size:1.73vw;
        font-weight:bold;
        transition: all 0.3s;
        @media (max-width: $tab) {
          width: auto;
          font-size: 28px;
        }
        &:after {
          content: "";
          display: block;
          width:0;
          border-bottom: 1px solid $white;
          transition: all 0.2s;
        }
      }
      .text__text{
        margin-top:10px;
        font-size: 30px;
        font-weight:bold;
        opacity:0;
        visibility: hidden;
        transform: translateY(35px);
        transition: all 0.4s;
        @media (max-width: $desksm) {
          font-size: 27px;
        }
        &--font-22{
          font-size: 22px;
          @media (max-width: $desksm) {
            font-size: 20px;
          }
          @media (max-width: $tab) {
            font-size: 27px;
          }
        }
      }
    }
    &:hover {
      width: 345px;
      @media (max-width: $tab) {
        width: 100%;
      }
      .item__img {
        @media (max-width: $tab) {
          height:500px !important
        }
      }
      .item__text {
        top: 130px;
        padding:0 35px;
        @media (max-width: $tab) {
          top: 8vw;
        }
        .text__date {
          width: auto;
          font-size: 50px;
          @media (max-width: $desksm) {
            font-size: 47px;
          }
          &:after {
            width: 50px;
            transition-delay: 0.2s;
          }
        }
        .text__text {
          opacity: 1;
          visibility: visible;
          transform: translateY(0);
          transition-delay: 0.4s;
        }
      }
    }
  }

}