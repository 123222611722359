.header{
  z-index: $z-menu;
  position: sticky;
  top:0;
  background:$white;

  .header__content{
    position:relative;
    display:flex;
    justify-content: center;
    height: 126px;
    @media (max-width: $tab) {
      height: 80px;
    }
    @media (max-width: $mob) {
      height: 65px;
    }
    .content__logo{
      position: absolute;
      top:31px;
      left:0;
      @media (max-width: $tab) {
        top: 10px;
      }
      @media (max-width: $mob) {
        top: 5px;
        left: calc(50% - 60px);
      }
      .logo{
        @media (max-width: $tab) {
          width:120px;
        }
        
        &--black{

        }
        &--white {
          display:none;
        }
      }
    }
    .content__menu{
      margin-left:100px;
      padding-top:63px;
      transition: all 0.2s;
      &.open{
        right: 0;
      }
      
      @media (max-width: $desksm) {
        position:fixed;
        top:126px;
        right:-100%;
        max-width:calc(100vw - 30px);
        height:calc(100vh - 126px);
        margin-left:0;
        padding: 15px 30px;
        box-sizing: border-box;
        background:$white;
      }
      @media (max-width: $tab) {
        top: 80px;
        height: calc(100vh - 80px);
        padding: 15px 20px;
      }
      @media (max-width: $mob) {
        top: 65px;
        height: calc(100vh - 65px);
        padding: 15px 15px;
      }
      .menu{
        display: flex;
        gap: 0 40px; 
        margin:0;
        padding:0;
        list-style: none;
        @media (max-width: $desksm) {
          display: block;
        }
        .menu-item{
          padding-bottom: 30px;
          font-size:12px;
          text-transform: uppercase;
          @media (max-width: $desksm) {
            font-size: 16px;
            padding-bottom:0;
          }
          @media (max-width: $mob) {
            font-size: 15px;
          }
          a{
            position:relative;
            color: $black;
            text-decoration:none;
            &:before{
              content: "";
              position:absolute;
              top:100%;
              right:50%;
              width:0%;
              padding-top:4px;
              border-bottom:1px solid $black;
              transition: all 0.2s;
              @media (max-width: $desksm) {
                display:none;
              }
            }
            &:after {
              content: "";
              position: absolute;
              top: 100%;
              left: 50%;
              width: 0%;
              padding-top: 4px;
              border-bottom: 1px solid $black;
              transition: all 0.2s;
              @media (max-width: $desksm) {
                  display: none;
                }
            }
            &:hover{
              &:before {
                width: 50%;
              }
              &:after {
                width: 50%;
              }
            }
          }
        }
        .menu-item-has-children {
          &:hover {
            .sub-menu {
              opacity: 1;
              visibility: visible;
            }
          }
          .sub-menu {
            z-index: $z-menu;
            position: fixed;
            top: 106px;
            left: 0;
            width: 100%;
            height:66px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap:0 50px;
            list-style: none;
            background:$creme;
            opacity: 0;
            visibility: hidden;
            transition: all 0.2s;

            @media (max-width: $desksm) {
              position: relative;
              top: auto;
              left: auto;
              opacity: 1;
              visibility: visible;
              display:block;
              height: auto;
              margin-top:10px;
              padding: 0;
              background: transparent;
              border: 0;
              border-radius: 0;
            }

            li {
              margin:0;
              padding: 0;
              @media (max-width: $desksm) {
                font-weight: 400;
                border: 0;
                margin-top: 0;
                margin-bottom: 10px;
                padding: 0 0 0 20px;
              }

              a {
                display: block;
                color:$black;
                @media (max-width: $desksm) {
                  color:$black;
                }
                &:before, &:after {
                  border-color:$black;
                }
              }
            }
          }
        }
      }
    }
    .content__lang{
      position: absolute;
      top: 63px;
      right: 0;
      display:flex;
      gap: 0 10px;
      margin:0;
      padding:0;
      list-style: none;
      font-size: 12px;
      text-transform: uppercase;
      
      @media (max-width: $desksm) {
        position: absolute;
        top: auto;
        right: auto;
        margin-top:35px;
        font-size: 16px;
      }
      @media (max-width: $mob) {
        font-size: 15px;
      }
      .lang-item{
        margin:0;
        padding-bottom:2px;
        &:hover{
          border-bottom: 1px solid $black;
        }
        &.current-lang {
          border-bottom: 1px solid $black;
        }
        a {
          color: $black;
          text-decoration: none;
        }
      }
      
      
    }
    .content__button-mobile{
      display:none;
      @media (max-width: $desksm) {
        display: block;
        position: absolute;
        top: 43px;
        right: 0;
        font-size:26px;
        cursor:pointer;
        transition: all 0.2s;
      }
      @media (max-width: $tab) {
        top: 20px;
      }
      @media (max-width: $mob) {
        top: 13px;
      }
      .button-mobile__open{

      }
      .button-mobile__close{
        display:none;
      }
      &.open{
        .button-mobile__open {
          display: none;
        }
        .button-mobile__close {
          display: block;
        }
      }
    }
  }
  &--home{
    transform: translateY(-126px);
    transition: all 0.2s;
    @media (max-width: $desksm) {
      transform: none;
    }
    &.active{
      transform: translateY(0);
    }
  }
}