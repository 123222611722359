.map{
    margin:40px 0;
    overflow: hidden;
    .map__content{
        position:relative;
        height:717px;
        
        @media (max-width: $desksm) {
            transform: scale(0.85);
            transform-origin:0
        }
        @media (max-width: $tab) {
            transform: scale(1);
            height:auto;
        }
        .content__title{
            display:none;
            @media (max-width: $tab) {
                display:block;
                font-size:24px;
                font-weight:bold;
                margin-top:60px;
                &:first-of-type{
                    margin-top: 0;
                }
            }
        }
        .content__continent{
            @media (max-width: $tab) {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                gap:40px 50px;
                margin-top:30px;
            }
        }
        .content__map-img{
            max-width: 1200px !important;
            @media (max-width: $tab) {
                display:none;
            }
        }
        .legend{
            position:absolute;
            bottom: 50px;
            left: 0;
            @media (max-width: $tab) {
                display: none;
            }
        }
        .pins-alt{
            @media (max-width: $tab) {
                display: none;
            }
            .pin {
                .pin__content {
                    .content__pin {
                        cursor: default;
                    }
                }
            }
        }
        .pin{
            position:absolute;
            @media (max-width: $tab) {
                position:relative;
                top:auto !important;
                left:auto!important;
            }
            .pin__content{
                position:relative;
                .content__img{
                    position:absolute;
                    max-width: none;
                    @media (max-width: $tab) {
                        display: none;
                    }
                }
                .content__selector-img{
                    position:absolute;
                    width:34px;
                    height:34px;
                    border-radius:34px;
                    cursor: pointer;
                    z-index: 1;
                    @media (max-width: $tab) {
                        display: none;
                    }
                    &:hover {
                        & ~ .content__pin {
                            z-index: 2;
                            .pin__details {
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                
                    }
                }
                .content__text{
                    position: absolute;
                    font-size: 16px;
                    font-weight: 700;
                    color:rgba($black, 0.7); 
                    cursor: pointer;
                    z-index: 1;
                    &:hover {
                        & + .content__pin{
                            z-index: 2;
                            .pin__details {
                                opacity: 1;
                                visibility: visible;
                            }
                        }
                        
                    }
                    @media (max-width: $tab) {
                        position: relative;
                        top: auto !important;
                        bottom: auto !important;
                        right: auto !important;
                        left: auto !important;
                        width: auto !important;
                        font-size:19px;
                        color: $black;
                    }
                }
                .content__pin {
                    z-index:1;
                    position:relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 17px;
                    height: 17px;
                    background: $rose;
                    border-radius: 17px;
                    color: $white;
                    font-size: 11px;
                    cursor: pointer;
                    @media (max-width: $tab) {
                        display:block;
                        width: auto;
                        height: auto;
                        background: transparent !important;
                    }
                    &:hover{
                        z-index: 2;
                        .pin__details {
                            opacity: 1;
                            visibility: visible;
                            
                        }
                    }
                    &--ocre {
                        background: $ocre;
                    }
                    &--green{
                        background: $green;
                    }
                    .fa-plus{
                        @media (max-width: $tab) {
                            display:none;
                        }
                    }
                    .pin__details{
                        position:absolute;
                        top:8px;
                        left:8px;
                        width:215px;
                        padding:25px 25px 1px 25px;
                        border:1px solid $grey;
                        border-radius: 0 10px 10px 10px;
                        background:rgba($white, 0.9);
                        color:$black;
                        font-size:16px;
                        cursor: default;
                        opacity: 0;
                        visibility: hidden;
                        transition: all 0.2s;
                        @media (max-width: $tab) {
                            position: relative;
                            top: auto !important;
                            bottom: auto !important;
                            left:auto !important;
                            right: auto !important;
                            padding: 0;
                            border: 0;
                            border-radius: 0;
                            background: none;
                            opacity: 1;
                            visibility: visible;
                        }
                        &--left{
                            left: auto;
                            right: 8px;
                            border-radius: 10px 0 10px 10px;
                        }
                        &--left-top {
                            top: auto;
                            bottom:8px;
                            left: auto;
                            right: 8px;
                            border-radius: 10px 10px 0 10px;
                        }
                        a{
                            color:$black;
                            text-decoration: none;
                            &:hover{
                                text-decoration: underline;
                            }
                        }
                    }
                }
            }

            &--wellingborough {
                top: 256px;
                left: 525px;
                .pin__content {
                    .content__img {
                        top: -151px;
                        left: -26px;
                        width: 37px;
                    }
                }
                .content__text {
                    top: -175px;
                    left: -68px;
                }
                .content__selector-img {
                    top: -149px;
                    left: -24px;
                    height:90px !important;
                }
            }
            &--noyant {
                top: 274px;
                left: 532px;
                .pin__content {
                    .content__img {
                        top: -44px;
                        left: -85px;
                        width: 87px;
                    }
                }
                .content__text {
                    top: -65px;
                    left: -95px;
                }
                .content__selector-img {
                    top: -42px;
                    left: -83px;
                }
            }
            &--levallois {
                top: 278px;
                left: 550px;
                .pin__content {
                    .content__img {
                        top: -59px;
                        left: -10px;
                        width: 38px;
                    }
                }
                .content__text {
                    top: -81px;
                    left: -25px;
                    width: 125px;
                }
                .content__selector-img{
                    top: -57px;
                    left: -8px;
                }
            }

            &--istanbul {
                top: 310px;
                left: 625px;
                .pin__content {
                    .content__img {
                        top: -59px;
                        left: -10px;
                        width: 38px;
                    }
                }

                .content__text {
                    top: -3px;
                    left: 22px;
                    width: 66px;
                }

                .content__selector-img {
                    top: -57px;
                    left: -8px;
                }
            }
            &--chartres {
                top: 291px;
                left: 535px;

                .pin__content {
                    .content__img {
                        top: 7px;
                        left: -131px;
                        width: 132px;
                    }
                }
                .content__text {
                    top: 116px;
                    left: -146px;
                }
                .content__selector-img {
                    top: 38px;
                    left: -130px;
                    height: 79px !important;
                }
            }
            &--seillans {
                top: 296px;
                left: 552px;
                .pin__content {
                    .content__img {
                        top: 16px;
                        left: 8px;
                        width: 49px;
                    }
                }
                .content__text {
                    top: 121px;
                    left: 8px;
                }
                .content__selector-img {
                    top: 87px;
                    left: 21px;
                }
            }
            &--dubai {
                top: 378px;
                left: 739px;
                .pin__content {
                    .content__img {
                        top: 16px;
                        left: -10px;
                        width: 38px;
                    }
                }
                .content__text {
                    top: 58px;
                    left: -12px;
                }
                .content__selector-img {
                    top: 24px;
                    left: -8px;
                }
            }
            &--hongkong {
                top: 358px;
                left: 967px;
                .pin__content {
                    .content__img {
                        top: -106px;
                        left: -50px;
                        width: 53px;
                    }
                }
                .content__text {
                    top: -4px;
                    left: 23px;
                    width: 90px;
                }
                .content__selector-img {
                    top: -103px;
                    left: -47px;
                    height: 82px !important;
                }
            }
            &--guangzhou {
                top: 364px;
                left: 935px;
                .pin__content {
                    .content__img {
                        top: -48px;
                        left: -90px;
                        width: 93px;
                    }
                }
                .content__text {
                    top:-4px;
                    left: -92px;
                    width: 90px;
                }
                .content__selector-img {
                    top: -46px;
                    left: -88px;
                }
            }
            &--mumbai {
                top: 395px;
                left: 807px;
                .pin__content {
                    .content__img {
                        top: 16px;
                        left: -10px;
                        width: 38px;
                    }
                }

                .content__text {
                    top: 110px;
                    left: -22px;
                }

                .content__selector-img {
                    top: 29px;
                    left: -8px;
                    height: 80px !important;
                }
            }
            &--jakarta {
                top: 494px;
                left: 932px;
                .pin__content {
                    .content__img {
                        top: 16px;
                        left: -10px;
                        width: 38px;
                    }
                }
                .content__text {
                    top: -22px;
                    left: -22px;
                }
                .content__selector-img {
                    top: 29px;
                    left: -8px;
                    height: 80px !important;
                }
            }
            &--vietnam {
                top: 420px;
                left: 927px;
                .content__text {
                    top: 17px;
                    left: -26px;
                    width: 73px;
                }
            }
            &--philippines {
                top: 408px;
                left: 980px;
                .content__text {
                    top: -4px;
                    left: 22px;
                }
            }
            &--piscataway {
                top: 281px;
                left: 284px;
                .pin__content {
                    .content__img {
                        top: 17px;
                        left: -10px;
                        width: 38px;
                    }
                }
                .content__text {
                    top: -5px;
                    left: -92px;
                }
                .content__selector-img {
                    top: 17px;
                    left: -10px;
                    height: 80px !important;
                }
            }
            &--canada{
                top: 251px;
                left: 284px;

                .content__text {
                    top: 16px;
                    left: -32px;
                }
            }
            &--mexique {
                top: 386px;
                left: 167px;

                .content__text {
                    top: 16px;
                    left: -32px;
                }
            }
            &--espagne {
                top: 311px;
                left: 515px;
                .content__text {
                    top: 15px;
                    left: -52px;
                }
            }
            &--israel {
                top: 350px;
                left: 651px;
            }

            &--algerie {
                top: 332px;
                left: 542px;
                .pin__content {
                    .content__img {
                        top: 10px;
                        left: -50px;
                        width: 58px;
                    }
                }
                .content__text {
                    top: 16px;
                    left: -12px;
                }
                .content__selector-img {
                    top: 10px;
                    left: -50px;
                    width: 58px!important;
                    height: 38px !important;
                }
                
                
                                
            }
            &--italie {
                top: 306px;
                left: 578px;

            }
            
            &--thailande {
                top: 403px;
                left: 907px;
            }
        }
    }
}