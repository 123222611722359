.text-img {
    position:relative;
    display: flex;

    @media (max-width: $mob) {
        flex-direction: column;
    }

    .text-img__text {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 50%;
        padding: 50px 0 50px 30px;
        box-sizing: border-box;

        @media (max-width: $mob) {
            width: 100%;
            padding: 60px 15px 40px 15px;
        }

        .text {
            max-width: 550px;
            margin: 0 50px 0 0;

            @media (max-width: $desksm) {
                margin: 0 30px 0 0;
            }

            @media (max-width: $mob) {
                margin: 0;
                max-width: none;
            }

            p {
                text-align: justify;
            }
            &--double-border{
                max-width: 600px;
                padding: 6px;
                border:2px solid $creme;
                box-sizing: border-box;
                .text__title{
                    padding: 50px 55px 22px;
                    border: 2px solid $creme;
                    @media (max-width: $desksm) {
                        padding: 40px 35px 22px;
                    }
                    @media (max-width: $tab) {
                        padding: 22px 15px;
                    }
                }
                .text__content{
                    padding: 30px 55px 20px;
                    border: 2px solid $creme;
                    border-top:0;
                    @media (max-width: $desksm) {
                        padding: 25px 35px 22px;
                    }
                    @media (max-width: $tab) {
                        padding: 22px 15px;
                    }
                }
            }
        }
    }

    .text-img__img {
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 100% !important;
        //max-height: 790px;
        object-fit: cover;
        object-position: center;

        @media (max-width: $mob) {
            position: relative;
            top: auto;
            left: auto;
            right: auto;
            width: 100%;
            height: auto !important;
    
        }
    }

    &--reverse {
        flex-direction: row-reverse;

        @media (max-width: $mob) {
            flex-direction: column;
        }

        .text-img__text {
            justify-content: flex-start;
            padding: 50px 30px 50px 0;

            @media (max-width: $mob) {
                width: 100%;
                padding: 60px 15px 40px 15px;
            }

            .text {
                margin: 0 0 0 50px;

                @media (max-width: $desksm) {
                    margin: 0 0 0 30px;
                }

                @media (max-width: $mob) {
                    margin: 0;
                }
                .text__content{
                    margin-top:35px;
                    padding-top:35px;
                    border-top:2px solid $creme;
                    &.no-top{
                        margin-top: 35px;
                        border-top: 2px solid $creme;
                    }
                }
            }
        }

        .text-img__img {
            left: 0;
            object-position: center right;
        }
    }
}
