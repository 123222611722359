.parfumerie-engagee{
  background:$creme;
  .parfumerie-engagee__block{
    background: $creme;
    position: sticky;
    top:126px;
    display:flex;
    align-items: center;
    height:calc(100vh - 126px);
    @media (max-width: $tab) {
      position: relative;
      top: auto;
      height: auto;
      padding-top: 50px;
    }
    .block__content {
      display: flex;
      align-items: center;
      width: 100%;
      height: 519px;
      text-align:center;
      text-decoration: none;
      @media (max-width: $tab) {
        flex-direction: column;
        justify-content: center !important;
        height: auto;
        padding: 0 0 15px 0;
        background: $green !important;
      }
      &:hover {
        .text__text {
          &:before {
            width: 50%;
          }

          &:after {
            width: 50%;
          }
        }
      }
      .content__img {
        display: none;
        margin-bottom: 15px;

        @media (max-width: $tab) {
          display: block;
          width: 100%;
        }
      }
      .content__text{
        display: flex;
        justify-content: center;
        width:430px;
        text-align:center;
        @media (max-width: $tab) {
          flex-direction: column;
          justify-content: center !important;
          width: 100%;
        }
      }
      .text__text {
        position: relative;
        display: block;
        width:fit-content;
        font-family: $font-2;
        font-size: 35px;
        font-weight:bold;
        color:$white;
        text-transform: uppercase;
        text-decoration:none;
        @media (max-width: $tab) {
          width: 100%;
          font-size: 35px;
        }
      
        @media (max-width: $mob) {
          font-size: 30px;
        }
        &:before {
          content: "";
          position: absolute;
          top: 100%;
          right: 50%;
          width: 0%;
          padding-top: 4px;
          border-bottom: 2px solid $white;
          transition: all 0.2s;
      
          @media (max-width: $tab) {
            display: none;
          }
        }
      
        &:after {
          content: "";
          position: absolute;
          top: 100%;
          left: 50%;
          width: 0%;
          padding-top: 4px;
          border-bottom: 2px solid $white;
          transition: all 0.2s;
      
          @media (max-width: $tab) {
            display: none;
          }
        }
      }
    }
    &--1{
      .block__content{
        justify-content: end;
        background: url(../images/bg-creations-naturelles@2x.jpg) no-repeat right;
        background-size: cover;
      }
    }
    &--2 {
      .block__content {
        background: url(../images/bg-ingredients@2x.jpg) no-repeat;
        background-size: cover;
      }
    }
    &--3 {
      .block__content {
        justify-content: end;
        background: url(../images/bg-rse@2x.jpg) no-repeat right;
        background-size: cover;
      }
    }
  }
}