@font-face {
    font-family: 'Didot';
    src: url('../webfonts/didot.woff2') format('woff2'),
        url('../webfonts/didot.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

$font-1 : "Raleway", sans-serif;
$font-2 : 'Didot', serif;