$white:         #ffffff;
$black:         #000000;
$grey:          #828282;
$grey-light:    #C9C9C9;

$creme:         #FAF4ED;
$ocre:          #A95935;
$ocre-light:    #d6926c;
$rose:          #B32951;
$green:         #8B8E69;
$marron:        #734D02;
$marron-clair:  #B5957E;

$rose-dark :    darken($rose, 5%);


$red:           #C94D1D;
$orange:        #E25A24;
$yellow:        #F1C219;

$blue:          #1976DC;




