.bonnes-pratiques{
  padding:50px 0 70px 0;
  background:$green;
  @media (max-width: $mob) {
    padding: 30px 0 50px 0;
  }
  .bonnes-pratiques__content{
    max-width:800px;
    margin:auto;
    text-align:center;
    img{
      display:inline;
    }
    p{
      font-family: $font-2;
      color:$white;
      font-size:25px;
      font-weight:bold;
      @media (max-width: $mob) {
        font-size: 20px;
      }
    }
  }
}