.natural-organic{
  
  padding:40px 0;
  background: rgb(169, 89, 53);
  background: linear-gradient(90deg, rgba(169, 89, 53, 1) 0%, rgba(228, 171, 132, 1) 35%, rgba(169, 89, 53, 1) 100%);
  .natural-organic__content{
    display: flex;
    justify-content: center;
    align-items: center;
    
    .content__img{
      @media (max-width: $mob) {
        width:150px
      }
    }

    
    .content__text{
      margin-left:35px;
      text-align:center;
      @media (max-width: $mob) {
        margin-left: 5px;
      }
      img{
        @media (max-width: $mob) {
          width: 100px;
          margin:auto;
        }
      }
      .text__title{
        margin-top:15px;
      }
    }
  }
}