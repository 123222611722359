.block-chiffres{
  padding:40px 0;
  .block-chiffres__container{
    border:2px solid $creme;
    padding:6px;
    .container__content{
      display:flex;
      justify-content: space-around;
      border: 2px solid $creme;
      @media (max-width: $tab) {
        flex-direction: column;
      }
      .content__item{
        padding:70px 0;
        font-family: $font-2;
        text-align:center;
        @media (max-width: $tab) {
          padding: 20px 0;
        }
        .item__number{
          font-size:90px;
          color:$rose;
          @media (max-width: $tab) {
            font-size: 50px;
          }
        }
        .item__text{
          font-size: 18px;
          font-weight: bold;
          color:$ocre;
          text-transform: uppercase;
        }
      }
    }
  }
}

