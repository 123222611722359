.home-block{
    .home-block__frise{
        position:relative;
        .frise__img{
            width:100%;
            height:154px !important;
            object-fit: cover;
            @media (max-width: $mob) {
                height: 100px !important;
            }
        }
        .frise__img-medaillon{
            position:absolute;
            top:45px;
            left: calc(50% - 83px);
            @media (max-width: $mob) {
                top: 35px;
                left: calc(50% - 50px);
                width:100px;
            }
        }
    }
    .home-block__content{
        display: flex;
        @media (max-width: $mob) {
            flex-direction: column;
        }
        .content__text {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            width:50%;
            padding:50px 0 50px 30px;
            box-sizing: border-box;
            @media (max-width: $mob) {
                width: 100%;
                padding: 60px 15px 40px 15px;
            }
            .text{
                max-width:500px;
                margin: 0 100px 0 0;
                @media (max-width: $desksm) {
                    margin: 0 50px 0 0;
                }
                @media (max-width: $mob) {
                    margin:0;
                    max-width: none;
                }
                p{
                    text-align: justify;
                }
            }
        }
        .content__img{
            width: 50%;
            max-height:620px;
            object-fit: cover;
            object-position: bottom left;
            @media (max-width: $mob) {
                width: 100%;
            }
        }

        &--reverse {
            flex-direction: row-reverse;
            @media (max-width: $mob) {
                flex-direction: column;
            }
            .content__text {
                justify-content: flex-start;
                padding: 50px 30px 50px 0;
                @media (max-width: $mob) {
                    width: 100%;
                    padding: 60px 15px 40px 15px;
                }
                .text {
                    margin: 0 0 0 100px;
                    @media (max-width: $desksm) {
                        margin: 0 0 0 50px;
                    }
                    @media (max-width: $mob) {
                        margin: 0;
                    }
                }
            }
            .content__img {
                object-position: bottom right;
            }
        }
    }
}