.talents{
  padding:100px 0;
  background: $creme;
  background-size: cover;
  .talents__content{
    max-width:720px;
    margin:auto;
    padding: 40px 120px 60px 120px;
    box-sizing: border-box;
    background:$white;
    text-align:center;
    @media (max-width: $mob) {
      padding: 40px 30px 50px 30px;
    }
  }
}